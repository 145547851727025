import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { initAxiosInterceptors } from './helpers/auth-helpers';
import VueNumber from 'vue-number-animation';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';

initAxiosInterceptors();
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueNumber);
Vue.use(store);
Vue.config.productionTip = false;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
