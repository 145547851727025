import API from './API';
import SweetalertService from '../service/SweetalertService';

export function initAxiosInterceptors() {
  API.interceptors.response.use(
    (response) => {
      document.body.classList.remove('loading-indicator');
      return response;
    },
    (error) => {
      SweetalertService.checkHttpErrorResponse(error);
      document.body.classList.remove('loading-indicator');
      return Promise.reject(error);
    }
  );
}
