<template>
  <div>
    <b-carousel
      id="carousel-1"
      v-model="slide"
      :interval="3000"
      controls
      indicators
      label-prev=""
      label-next=""
      img-width="1024"
      img-height="480"
      style="text-shadow: 1px 1px 2px #333"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
    >
      <CarouselItem :carousel-items="carouselItems" />
    </b-carousel>
  </div>
</template>

<script>
import CarouselItem from './CarouselItem.vue';

export default {
  components: {
    CarouselItem,
  },
  props: {
    carouselItems: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      slide: 0,
      sliding: null,
    };
  },
  methods: {
    onSlideStart() {
      this.sliding = true;
    },
    onSlideEnd() {
      this.sliding = false;
    },
  },
};
</script>
