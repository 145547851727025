import Api from '../../helpers/API';

class UFService {
  url = '/he/maintainer/ufs';

  getUFDay = async () => {
    return Api.get(`${this.url}/day`);
  };
}

export default new UFService();
