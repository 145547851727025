<template>
  <div id="app">
    <Menu class="hidden_desktop" />
    <MenuMovil class="hidden_movil" />

    <main>
      <router-view />
    </main>

    <Footer />
  </div>
</template>

<script>
import Menu from './components/globales/Menu.vue';
import MenuMovil from './components/globales/MenuMovil.vue';
import Footer from './components/globales/Footer.vue';

export default {
  name: 'App',
  components: {
    Menu,
    MenuMovil,
    Footer,
  },
};
</script>

<style>
main {
  flex: 1;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
