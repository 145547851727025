export const itemsDesktop = [
  {
    alt: 'Subsidio',
    imgSrc: 'img/carousel/banner-subsidio-2507-1980x600.jpg',
    link: '/home-subsidio',
  },
  {
    alt: 'Sucursal Virtual',
    imgSrc: 'img/carousel/banner-sucursal-virtual_1980x600.jpg',
    link: '',
  },
  {
    alt: 'Crédito Hogar',
    imgSrc: 'img/carousel/banners-new-hogar_1980x600.jpg',
  },
];

export const itemsMobile = [
  {
    alt: 'Métodos de Pago',
    imgSrc: 'img/carousel/banner-sucursal-2507_600x900.jpg',
  },
  {
    alt: 'Sucursal Virtual',
    imgSrc: 'img/carousel/banner-sucursal-virtual_600x900.jpg',
  },
  {
    alt: 'Crédito Hogar',
    imgSrc: 'img/carousel/banners-movil-new-hogar_600x900.jpg',
  },
];
