class CommonService {
  formatDate(date) {
    const day = date.getDate();
    const year = date.getFullYear();
    let month = String(date.getMonth() + 1);
    month = month.padStart(2, '0');
    return `${year}${month}${day}`;
  }

  downloadFile(data, filename, contentType) {
    const fileName = filename;
    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
      const byteCharacters = atob(data);
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: contentType });
      window.navigator.msSaveOrOpenBlob(blob, fileName);
    } else {
      const link = document.createElement('a');
      link.href = `data:${contentType};base64,` + data;
      link.setAttribute('download', fileName);
      link.style.display = 'none';
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
  }

  scrollToTop() {
    window.scrollTo(0, 0);
  }

  validateEmail(email) {
    const regex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
    return regex.test(email);
  }

  cleanRut(_value) {
    return typeof _value === 'string'
      ? _value.replace(/[^0-9kK]+/g, '').toUpperCase()
      : '';
  }

  formatRut(_value) {
    _value = this.cleanRut(_value);

    if (!_value) {
      return null;
    }
    if (_value.length <= 1) {
      return _value;
    }

    let result = _value.slice(-4, -1) + '-' + _value.substr(_value.length - 1);
    for (let i = 4; i < _value.length; i += 3) {
      result = _value.slice(-3 - i, -i) + '.' + result;
    }
    return result;
  }

  checkRut(inputRut) {
    if (inputRut === null) {
      return null;
    }
    const rut = String(inputRut);
    let i;
    let result = 0;
    let serie = 2;
    let mod;
    let vdv;

    for (i = rut.length - 1; i >= 0; i--) {
      result += +rut.charAt(i) * serie;
      serie++;
      if (serie > 7) {
        serie = 2;
      }
    }

    mod = result % 11;
    vdv = 11 - mod;

    if (vdv === 11) {
      vdv = 0;
    }
    if (vdv === 10) {
      vdv = 'K';
    }

    return String(vdv);
  }

  getUnFormattedNumber(value) {
    let response;
    if (value !== undefined && value !== null) {
      response = String(value).replace('$', '');
      response = response.replace('%', '');
      response = response.replace('UF', '');
      response = response.replace('_', '');
      response = response.replace('-', '');
      response = response.replace(' ', '');
      response = response.split(',').join('');
      response = response.split('.').join('');

      if (isNaN(+response)) {
        response = null;
      }
    } else {
      response = null;
    }

    return +response;
  }
}

export default new CommonService();
