import { URL_POPUP } from '../../config/Global';
import API from '../../helpers/API';
import CommonService from '../CommonService';

class PopUpService {
  url = URL_POPUP;

  findByDate = async (date) => {
    const dateString = CommonService.formatDate(date);
    return API.get(`${this.url}/find-by-date/${dateString}`);
  };
}

export default new PopUpService();
