<template>
  <div class="home">
    <!-- HEADER PRINCIPAL  4 -->
    <section class="header-principal pt-5">
      <Carousel :carousel-items="carouselItemsDesktop" class="hidden_desktop" />
      <Carousel :carousel-items="carouselItemsMobile" class="hidden_movil" />
    </section>

    <!-- ==> Renderizamos Form Express  -->
    <FormExpress />

    <!-- HEADER PRINCIPAL  -->

    <!-- ===>CONTENIDO<===  -->

    <!-- ===============
BLOQUE INFORMATIVO  (CMF)
=====================  -->
    <section id="primero">
      <div class="container">
        <div class="row p-10">
          <div class="col-lg-6 border_dashed">
            <h1 class="title_azul">No somos un Banco.</h1>
            <h2 class="txt_light_azul">
              <span class="plus_rosa">+</span> rápido
              <span class="plus_rosa">+</span> simples
            </h2>
          </div>

          <div class="col-lg-6">
            <p class="p-cmf">Estamos regulados por la CMF</p>
            <img src="img/home/cmf.png" class="w-50" alt="Logo de CMF" />
          </div>
        </div>
      </div>
    </section>
    <!-- =============== FIN
BLOQUE INFORMATIVO  (CMF)
=====================  -->

    <!-- ===============
BLOQUE INFORMATIVO BG HOUSE AZUL
=====================  -->
    <section ref="animationSection" class="bg-gris-2">
      <div class="container-fluid">
        <div class="row">
          <div class="col-lg-6 bg-home"></div>
          <div class="col-lg-4 pminTop-10">
            <h2 class="txt_light_azul">
              <span class="plus_rosa">+</span> de
              <number
                ref="uf"
                :from="ufFrom"
                :to="ufTo"
                animation-paused
                :duration="duration"
                :delay="delay"
                easing="Power1.easeOut"
              />
              millones de UF otorgadas.
              <br />
              <span class="plus_rosa">+</span>
              <strong>
                de
                <number
                  ref="clients"
                  :from="clientsFrom"
                  :to="clientsTo"
                  animation-paused
                  :duration="duration"
                  :delay="delay"
                  easing="Power1.easeOut"
                />
              </strong>
              clientes.
            </h2>
            <h3 class="text-light_dest">
              <span class="plus_rosa">¡</span>Evoluciona con nosotros<span
                class="plus_rosa"
                >!</span
              >
            </h3>
          </div>
        </div>
      </div>
    </section>
    <!-- =============== FIN
BLOQUE INFORMATIVO BG HOUSE AZUL
=====================  -->

    <!-- ===============
BLOQUE LOGOS FRONTAL Y MONEDA
=====================  -->

    <section class="bg-gris p-5">
      <div class="container aju_con">
        <div class="col-lg-12">
          <h2 class="somos">Somos parte de</h2>
        </div>
        <div class="row">
          <div class="col-lg-2"></div>
          <div class="col-lg-4">
            <a href="https://www.frontaltrust.cl/">
              <img class="w-100 p-4" src="img/logo_frontal_trust.svg" />
            </a>
          </div>

          <div class="col-lg-4">
            <a href="https://www.moneda.cl/">
              <img
                class="w-100 p-4"
                src="img/logo_moneda_asset.svg"
                alt="Moneda Logo"
              />
            </a>
          </div>
          <div class="col-lg-2"></div>
        </div>
      </div>
    </section>
    <!-- =============== FIN
BLOQUE LOGOS FRONTAL Y MONEDA
=====================  -->

    <!-- ===============
BLOQUE TESTIMONIOS
=====================  -->
    <section class="testimonios p-4">
      <div class="text-center">
        <h1 class="title_test pt-4">Testimonios</h1>
        <h2 class="subtitulo_test">Lo que dicen nuestros clientes</h2>
      </div>

      <div class="container">
        <div class="row d-flex justify-content-center text-center p-4">
          <!-- BOX TESTIMONIO  -->
          <figure class="card-testimonio col-lg-3 text-left">
            <div class="d-flex star">
              <i class="bi bi-star-fill star_left"></i>
              <i class="bi bi-star-fill star_left"></i>
              <i class="bi bi-star-fill star_left"></i>
              <i class="bi bi-star-fill star_left"></i>
              <i class="bi bi-star-fill star_left"></i>
            </div>
            <p class="txt-testimonio">
              Me permitieron concretar la compra de otro inmueble, ademas la
              comunicación con todos es muy rapida, siempre.
            </p>
            <span class="name_test">Nataly</span> <br />
            <span class="edad_rosa">36 años</span>
          </figure>
          <!-- BOX TESTIMONIO  -->
          <!-- BOX TESTIMONIO  -->
          <figure class="card-testimonio col-lg-3 text-left">
            <div class="d-flex star">
              <i class="bi bi-star-fill star_left"></i>
              <i class="bi bi-star-fill star_left"></i>
              <i class="bi bi-star-fill star_left"></i>
              <i class="bi bi-star-fill star_left"></i>
              <i class="bi bi-star-fill star_left"></i>
            </div>
            <p class="txt-testimonio">
              Tuve desde un principio una muy buena atención y siempre han
              intentado poder cumplir con mis necesidades
            </p>
            <span class="name_test">Felipe</span> <br />
            <span class="edad_rosa">29 años</span>
          </figure>
          <!-- BOX TESTIMONIO  -->
          <!-- BOX TESTIMONIO  -->
          <figure class="card-testimonio col-lg-3 text-left">
            <div class="d-flex star">
              <i class="bi bi-star-fill star_left"></i>
              <i class="bi bi-star-fill star_left"></i>
              <i class="bi bi-star-fill star_left"></i>
              <i class="bi bi-star-fill star_left"></i>
              <i class="bi bi-star-fill star_left"></i>
            </div>
            <p class="txt-testimonio">
              La atención y gestión del proceso fue rápida con un equipo de
              postventa y página amigable y de fácil uso para el pago de las
              cuotas.
            </p>
            <span class="name_test">Marcelo</span> <br />
            <span class="edad_rosa">39 años</span>
          </figure>
          <!-- BOX TESTIMONIO  -->
          <!-- BOX TESTIMONIO  -->
          <figure class="card-testimonio col-lg-3 text-left">
            <div class="d-flex star">
              <i class="bi bi-star-fill star_left"></i>
              <i class="bi bi-star-fill star_left"></i>
              <i class="bi bi-star-fill star_left"></i>
              <i class="bi bi-star-fill star_left"></i>
              <i class="bi bi-star-fill star_left"></i>
            </div>
            <p class="txt-testimonio">
              Me dieron la mejor tasa entre las mutuarias. Los trámites fueron
              simples y expeditos. Cuando tuve un problema con el cálculo del
              dividendo lo solucionaron súper rápido
            </p>
            <span class="name_test">Diana</span> <br />
            <span class="edad_rosa">32 años</span>
          </figure>
          <!-- BOX TESTIMONIO  -->
          <!-- BOX TESTIMONIO  -->
          <figure class="card-testimonio col-lg-3 text-left">
            <div class="d-flex star">
              <i class="bi bi-star-fill star_left"></i>
              <i class="bi bi-star-fill star_left"></i>
              <i class="bi bi-star-fill star_left"></i>
              <i class="bi bi-star-fill star_left"></i>
              <i class="bi bi-star-fill star_left"></i>
            </div>
            <p class="txt-testimonio">
              Tuve una muy buena experiencia de servicio con la ejecutiva, el
              proceso de estudio de títulos fue rápido y la escrituración
              también.
            </p>
            <span class="name_test">Cristian</span> <br />
            <span class="edad_rosa">45 años</span>
          </figure>
          <!-- BOX TESTIMONIO  -->
          <!-- BOX TESTIMONIO  -->
          <figure class="card-testimonio col-lg-3 text-left">
            <div class="d-flex star">
              <i class="bi bi-star-fill star_left"></i>
              <i class="bi bi-star-fill star_left"></i>
              <i class="bi bi-star-fill star_left"></i>
              <i class="bi bi-star-fill star_left"></i>
              <i class="bi bi-star-fill star_left"></i>
            </div>
            <p class="txt-testimonio">
              Agilidad de la ejecutiva, buena disposición al momento de cotizar
              y evaluar. Buenas tasas de interés.
            </p>
            <span class="name_test">Esteban</span> <br />
            <span class="edad_rosa">38 años</span>
          </figure>
          <!-- BOX TESTIMONIO  -->
        </div>
      </div>
    </section>
    <!-- ===============  FIN
BLOQUE TESTIMONIOS
=====================  -->

    <!-- ===============
BLOQUE SIMPLE Y FACIL
=====================  -->

    <section class="container-fluid p-5 mb-4 text-center">
      <h1 class="title_icon">Simple y Fácil</h1>

      <img
        src="img/iconos_simple2.svg"
        class="p-10 hidden_desktop"
        width="100%"
        alt=""
      />
      <img
        src="img/simple_movil2.svg"
        class="p-10 hidden_movil mb-4"
        width="100%"
        alt=""
      />
    </section>
    <!-- ===============  FIN
BLOQUE SIMPLE Y FACIL
=====================  -->

    <!-- ===============
BLOQUE SIN COMISION
=====================  -->

    <section class="container aju_con aju_bottom text-center">
      <h1 class="title_comin">Sin comisiones</h1>
      <h1 class="sub_comin">Sin cuentas corrientes u otro producto bancario</h1>
      <h1 class="ad_comin">
        <span class="plus_comin">+</span> rápidos
        <span class="plus_comin">+</span> simples
      </h1>
      <p class="tx-web">
        Paga tu dividendo mes a mes 100% online
        <a href="/medios-de-pago"> aquí</a> <br />
      </p>
    </section>
    <!-- ===============  FIN
BLOQUE SIN COMISION
=====================  -->

    <!--    Modal-->

    <div
      id="notice"
      class="modal fade modal-bg-simulador"
      abindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      :style="[
        {
          'padding-top': '0',
          'background-color': 'rgb(0, 64, 143, ' + imgOpacity + ') !important',
        },
      ]"
    >
      <div class="modal-vertical-alignment">
        <div
          class="modal-dialog modal-md modal-vertical-align-center"
          :style="[{ top: '25%', 'max-width': imgSize }]"
        >
          <div class="modal-content modal-vertical-content">
            <div class="modal-body-simulador">
              <div class="modal-body-simulador">
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <i class="bi bi-x-lg close_color popup-close-mod"></i>
                </button>
                <img
                  class="img-fluid img-popup popup-img-mod"
                  :src="'data:image/jpg;base64,' + imgBase64"
                  :alt="imgName"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->

    <!-- ===>CONTENIDO<===   -->

    <!-- ==> Renderizamos Footer  -->
  </div>
</template>

<script>
import FormExpress from '../components/FormExpress.vue';
import CommonService from '../service/CommonService';
import Carousel from '../components/globales/Carousel.vue';
import { itemsDesktop, itemsMobile } from '../config/carouseItems';
import store from '../store/index';
import { creditTypes } from '../data/creditTypes';
import Modal from 'bootstrap/js/dist/modal';
import PopUpService from '../service/rest/PopUp.service';

export default {
  name: 'Home',
  components: {
    FormExpress,
    Carousel,
  },
  data() {
    return {
      ufFrom: 1,
      clientsFrom: 1,
      ufTo: 16,
      clientsTo: 1700,
      duration: 2,
      delay: 2,
      minTop: 650,
      maxTop: -300,
      scaleClass: false,
      references: [],
      carouselItemsDesktop: itemsDesktop,
      carouselItemsMobile: itemsMobile,
      creditTypes,
      imgBase64: '',
      imgName: '',
      imgSize: '',
      imgOpacity: '',
    };
  },
  created: function () {
    store.commit('changeCreditType', creditTypes.PRINCIPAL);
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
    CommonService.scrollToTop();
    this.references = Object.entries(this.$refs).filter(
      (r) => r[0] != 'animationSection'
    );

    PopUpService.findByDate(new Date()).then((res) => {
      const { data } = res;
      if (data) {
        this.imgBase64 = data.imgBase64;
        this.imgName = data.fileName;
        this.imgSize = data.popupSizeEnum ? data.popupSizeEnum.width : '30%';
        this.imgOpacity = data.opacityWebEnum ? data.opacityWebEnum.value : '1';
        this.openModal();
      }
    });
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  methods: {
    theFormat(number) {
      return String('$' + number.toFixed(0));
    },
    playAnimation() {
      this.references.forEach((r) => r[1].play());
    },
    restarAnimation() {
      this.references.forEach((r) => {
        r[1].restart();
        r[1].pause();
      });
    },
    handleScroll() {
      const sectionTop =
        this.$refs.animationSection.getBoundingClientRect().top;
      if (sectionTop <= this.minTop && sectionTop >= this.maxTop) {
        this.playAnimation();
      }
      if (sectionTop < this.maxTop || sectionTop > this.minTop) {
        this.restarAnimation();
      }
    },
    openModal() {
      const myModal = new Modal(document.getElementById('notice'), {
        keyboard: false,
      });

      myModal.show();
    },
  },
};
</script>
