var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"form-express"},[_c('div',{attrs:{"id":"efec-minimizar"}},[_c('a',{on:{"click":function($event){_vm.show = !_vm.show}}},[_c('img',{staticClass:"float-end",attrs:{"src":"img/icon-close.png","alt":"icono close"}})]),_vm._m(0),_c('transition',{attrs:{"name":"custom-classes-transition","enter-active-class":"animated fadeIn","leave-active-class":"animated fadeOut"}},[(_vm.show)?_c('div',{staticClass:"contenido-form"},[_c('form',{attrs:{"method":"post"},on:{"submit":function($event){$event.preventDefault();return _vm.cotizarRenta.apply(null, arguments)}}},[_c('p',{staticClass:"txt-formexpres"},[_vm._v("Tu renta liquida ($)")]),_c('CurrencyInput',{staticClass:"input-express text-center",attrs:{"options":{
              locale: 'es-CL',
              currency: 'CLP',
              currencyDisplay: 'hidden',
              precision: 0,
              hideGroupingSeparatorOnFocus: false,
              valueRange: {
                min: 0,
                max: 100000000,
              },
            }},model:{value:(_vm.renta),callback:function ($$v) {_vm.renta=$$v},expression:"renta"}}),_c('br'),_c('button',{staticClass:"btn btn-calcular w-50",attrs:{"disabled":_vm.renta == null}},[_vm._v(" CALCULAR ")])],1),_c('div',{staticClass:"resultado-express p-3"},[_c('div',{staticClass:"row linea-inferior p-1"},[_c('div',{staticClass:"col-lg-8 text-express"},[_vm._v("Dividendo máx. ($)")]),_c('div',{staticClass:"col-lg-4 text-express-r"},[(_vm.status != 200)?_c('span',[_vm._v(" 0 ")]):_vm._e(),(_vm.status == 200)?_c('span',[_vm._v(" "+_vm._s(_vm.formatPrice(_vm.valor.dividend * _vm.ufhoy)))]):_vm._e()])]),_c('div',{staticClass:"row p-1"},[_c('div',{staticClass:"col-lg-8 text-express"},[_vm._v("Crédito max (UF)")]),_c('div',{staticClass:"col-lg-4 text-express-r"},[(_vm.status != 200)?_c('span',[_vm._v(" 0 ")]):_vm._e(),(_vm.status == 200)?_c('span',[_vm._v(_vm._s(_vm.formatPrice(_vm.valor.credit)))]):_vm._e()])])]),_c('div',{staticClass:"cotizar"},[_c('span',{staticClass:"text-cotizar"},[_vm._v("para continuar simulación")]),_vm._v(" "),_c('br'),_c('router-link',{staticClass:"click linea-inferior-celeste",attrs:{"to":"/home-hogar"}},[_vm._v(" Click Aquí ")])],1)]):_vm._e()])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"encabezado-express"},[_c('h3',{staticClass:"titulo-express"},[_vm._v("SIMULADOR RÁPIDO")])])}]

export { render, staticRenderFns }