/*eslint-disable */
import Swal from 'sweetalert2';
import { ColorTheme } from '../config/Global';

class SweetalertService {
  confirmButtonColor = ColorTheme;

  cancelButtonColor = '#777';
  dangerButtonColor = '#cb2535';

  getSweetAlertTop = (title, icon, timer) => {
    const toast = Swal.mixin({
      toast: true,
      position: 'top-right',
      showConfirmButton: false,
      timer: timer ? timer : 3000,
      timerProgressBar: true,
      didOpen: (t) => {
        t.addEventListener('mouseenter', Swal.stopTimer);
        t.addEventListener('mouseleave', Swal.resumeTimer);
        t.addEventListener('click', Swal.clickCancel);
      },
    });

    return toast.fire({
      icon: icon,
      title: `<p style="color: #1e3795;">${title}</p>`,
    });
  };

  getSweetLoading = (title, text) => {
    Swal.fire({
      title: title ? `<h2 style="color: #1e3795;">${title}</h2>` : null,
      allowOutsideClick: false,
      text: text ? text : null,
    });
    return Swal.showLoading();
  };

  checkHttpErrorResponse = (error) => {
    const title = 'Problemas para realizar la operación';
    // hay endPoint
    if (error.response) {
      if (error.response.status === 510) {
        Swal.fire({
          title,
          // error controlado
          html: `<p class="text-warning">${error.response.data.message}</p>`,
          icon: 'warning',
        });
      }
      if (error.response.status === 500) {
        Swal.fire({
          title,
          html: `<p class="text-warning">${error.response.status} ${error.response.data.cause.message}</p>`,
          icon: 'warning',
        });
      }
      if (error.response.status === 401) {
        if (error.response.config.url === '/login') {
          this.getSweetAlertTop('Usuario o contraseña incorrectos', 'error');
        }
      }
    } else {
      Swal.fire(this.configSweet(title, error)).then((result) => {
        console.log(result);
        if (result.value) {
          deleteToken();
          deleteUsername();
          window.location.href = '/login';
        }
      });
    }
  };
}

export default new SweetalertService();
