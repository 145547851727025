import API from '../../helpers/API';
import { URL_SIMULATION } from '../../config/Global';

class SimulationService {
  url = URL_SIMULATION;

  simulateWeb = async (simulationWebQuery) => {
    return API.post(`${this.url}/simulate-web`, simulationWebQuery);
  };

  sendSimulation = async (simulationQuery) => {
    return API.post(`${this.url}/send-simulation`, simulationQuery);
  };

  contact = async (contact) => {
    return API.post(`${this.url}/contact`, contact);
  };

  printSimulation = async (simulationId) => {
    return API.get(`${this.url}/${simulationId}/print-simulate`);
  };

  simulationExpress = async (income) => {
    return API.get(`${this.url}/simulate-express?income=${income}`);
  };
}

export default new SimulationService();
