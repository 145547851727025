export const INPUT_STRING_MAX_LENGTH = 150;
export const INPUT_STRING_TEXTAREA_MAX_LENGTH = 1000;
export const INPUT_STRING_RUT_MIN_LENGTH = 7;
export const INPUT_STRING_RUT_MAX_LENGTH = 8;
export const INPUT_STRING_PHONE_MIN_LENGTH = 9;
export const INPUT_STRING_PHONE_MAX_LENGTH = 9;
export const INPUT_STRING_DOCUMENT_NUMBER_MIN_LENGTH = 9;
export const INPUT_STRING_DOCUMENT_NUMBER_MAX_LENGTH = 10;
export const INPUT_NUMBER_TERM_MIN = 96;
export const INPUT_NUMBER_TERM_MAX = 480;
// nuevos por ocupar
export const MAX_VALUE_PESOS = 9999999999;
export const MAX_VALUE_DOLAR = 9999999;
export const MAX_VALUE_UF = 999999;
export const DECIMAL_DOLAR = 2;
export const DECIMAL_UF = 4;
export const DECIMAL_RATE = 2;
export const RECAPTCHA_SITE_KEY = '6Le3esoZAAAAAHUgmwxgfxfO0gMOvxeUyRoYDxhU';
export const LOGIN = true;
export const MORTGAGE_NAME =
  'Evoluciona Administradora de Mutuos Hipotecarios S.A.';
export const TOKEN_KEY = 'TEST-TOKEN';
export const USERNAME_KEY = 'USERNAME-CURRENT';
export const useFakeOperation = false;
export const NavbarThemesClass = {
  userOn: {
    navBar: 'navbar_persona',
    footer: 'footer_persona',
    color: '#8CC63F',
  },
  userOff: {
    navBar: 'navbar_persona_off',
    footer: 'footer_persona_off',
    color: '#ED1E79',
  },
};
export const DefaultRegionForm = 7;
export const LIMIT_YEARS = 20;
export const ColorTheme = ' #18349b';
export const MAX_PAID = 3000000;
export const URL_SIMULATION =
  process.env.NODE_ENV === 'production'
    ? '/simulation-web'
    : '/he/commercial/simulations/web';
export const URL_POPUP = '/he/maintainer/pop-up';
