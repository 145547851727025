import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
  /* RUTAS GLOBALES */

  {
    path: '/Nosotros',
    name: 'Nosotros Home',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/NosotrosHome.vue'),
  },
  {
    path: '/preguntas-frecuentes',
    name: 'preguntas',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Preguntas.vue'),
  },
  {
    path: '/Asesoramos',
    name: 'Te Asesoramos',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Asesoramos.vue'),
  },
  {
    path: '/Licitaciones',
    name: 'Licitaciones',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/Licitaciones.vue'),
  },
  {
    path: '/medios-de-pago',
    name: 'Medios de Pago',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/MediosDePago.vue'),
  },
  {
    path: '/como-pagar',
    name: 'Como Pagar',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/ComoPagar.vue'),
  },
  {
    path: '/consideraciones-generales',
    name: 'Consideraciones Generales',
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/ConsideracionesGeneralesDePago.vue'
      ),
  },

  /* RUTAS PARA HOGAR */
  {
    path: '/home-hogar',
    name: 'Home Hogar',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/hogar/Home.vue'),
  },

  {
    path: '/nosotros-hogar',
    name: 'Nosotros Hogar',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/hogar/Nosotros.vue'),
  },
  {
    path: '/asesoramos-hogar',
    name: 'Asesoramos Hogar',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/hogar/Asesoramos.vue'),
  },
  {
    path: '/preguntas-hogar',
    name: 'Preguntas Hogar',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/hogar/Preguntas.vue'),
  },
  {
    path: '/formularios',
    name: 'Formularios',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/hogar/Formularios.vue'),
  },
  {
    path: '/footer',
    name: 'Footer',
    component: () => import('../views/Footer.vue'),
  },
  /* RUTAS PARA EMPRESAS */
  {
    path: '/home-empresas',
    name: 'Home Empresas',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/empresas/Home.vue'),
  },

  {
    path: '/nosotros-empresas',
    name: 'Nosotros Empresas',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/empresas/Nosotros.vue'),
  },
  {
    path: '/asesoramos-empresas',
    name: 'Asesoramos Empresas',
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/empresas/Asesoramos.vue'
      ),
  },
  {
    path: '/preguntas-empresas',
    name: 'Preguntas Empresas',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/empresas/Preguntas.vue'),
  },
  {
    path: '/formularios-empresas',
    name: 'Formularios Empresas',
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/empresas/Formularios.vue'
      ),
  },
  /* RUTAS PARA SUBSIDIO */
  {
    path: '/home-subsidio',
    name: 'Home Subsidio',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/subsidio/Home.vue'),
  },

  {
    path: '/nosotros-subsidio',
    name: 'Nosotros Subsidio',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/subsidio/Nosotros.vue'),
  },
  {
    path: '/asesoramos-subsidio',
    name: 'Asesoramos Subsidio',
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/subsidio/Asesoramos.vue'
      ),
  },
  {
    path: '/preguntas-subsidio',
    name: 'Preguntas Subsidio',
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/subsidio/Preguntas.vue'),
  },
  {
    path: '/formularios-subsidio',
    name: 'Formularios Subsidio ',
    component: () =>
      import(
        /* webpackChunkName: "about" */ '../views/subsidio/Formularios.vue'
      ),
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
