import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    creditType: 'home',
  },
  mutations: {
    changeCreditType(state, type = 'home') {
      state.creditType = type;
    },
  },
  actions: {},
  modules: {},
});
